.color-block {
    width: 0.5rem, !important;
    height: 5rem;
    display: none;
}

.live-text {
    color: #f90205 !important;
    text-shadow: 0 0 20px #f90205;
}

.richmond-logo-scorecard {
    height: 5rem !important;
}

.live-text::before {
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #f90205;
    margin-right: 8px;
}