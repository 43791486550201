.blogs-page {
    min-height: 100vh;
    background: url("../../assets//img/home-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    left: 0;
    top: 0;
    background-color: var(--primary-color) !important;
}

.blog-card-grid {
    margin-top: 4rem !important;
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(300px, 2fr));
}

.blog-author {
    height: 5rem;
}

.blog-page-column-content {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
}

.left-blog-card {
    background: rgba(255, 255, 255, 0.05) !important;
    box-shadow: 0px 60px 70px -35px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.left-blog-card-active {
    border: 1px solid var(--richmond-blue) !important;
}

.left-blog-card h3, .left-blog-card p {
    color: #fff !important;
}

.right-blog-card {
    background: rgba(255, 255, 255, 0.05) !important;
    box-shadow: 0px 60px 70px -35px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 2rem;
}

.blog-rich-text p {
    margin-bottom: 10px;
}

.blog-site-footer {
    background-color: transparent !important;
}