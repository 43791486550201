.navbar {
    background-color: transparent !important;
    padding-top: 1rem;
}

.navbar-item {
    color: #fff !important;
    font-weight: 500 !important
}

.navbar-item:hover {
    background-color: transparent !important;
}

.navbar-item:focus-within {
    background-color: transparent !important;
}

.navbar-menu {
    padding-left: 7rem;
    padding-right: 7rem;
}

.navbar-icon {
    color: #fff !important;
    font-size: 20px;
}

.navbar-menu {
    background-color: transparent !important;
}

.navbar-menu.is-active {
    background-color: #00000066 !important;
    text-align: center !important;
}