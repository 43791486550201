.title-column{
    background-color: rgba(255, 255, 255, 0.17);
    border-radius: 7px;
}

@media only screen and (max-width: 733px) {
    .title-column h1{
        font-size: 12px !important;
    }
    .singe-inning-card h1{
        font-size: 12px !important;
    }
    .innings-final-scores h1{
        font-size: 12px !important;
    }
}