/* Import Fonts */
@font-face {
  font-family: SF-Pro;
  src: url(assets/fonts/SFPRODISPLAYREGULAR.OTF);
}

@font-face {
  font-family: SF-light;
  src: url(./assets/fonts/SF_Pro_Display.woff);
}

@font-face {
  font-family: SF-Bold;
  src: url(./assets/fonts/SFPRODISPLAYBOLD.OTF);
}

/* Import Font Awesome */
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css");
/* Import Animate CSS */
@import url('https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css');

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: SF-Pro !important;
  color: white !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Color Palette */
:root {
  --primary-color: #050514;
  --richmond-blue: #5e56ff;
  --mahinda-gold: #FFB800;
  --black: #000000;
  --white: #ffffff;
}

/* variable for a color */


.has-blue-background {
  background-color: var(--primary-color) !important;
}

.has-richmond-blue-background {
  background-color: var(--richmond-blue) !important;
}

.has-gold-background {
  background-color: var(--mahinda-gold) !important;
}

.rl-inner-sponser-container {
  padding: 30px 0;
}

.rl-sponsor-cont {
  text-align: center;
}

.rl-sponsor-cont-topic {
  padding: 10px 0;
}

.has-white-text {
  color: var(--white) !important;
}

.low-opacity-text {
  opacity: 0.5 !important;
}

/* Styles */